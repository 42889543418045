(function() {
	'use strict';

	$( '#offcanvas-toggle' ).on( 'click', function( e ) {
		e.preventDefault();

		if ( $( 'body' ).hasClass( 'offcanvas-active' ) ) {
			$( 'body' ).removeClass( 'offcanvas-active' );
			setTimeout( function() {
				$( '#offcanvas' ).hide();
			}, 500 );
			$( this ).attr( 'aria-expanded', 'false' );
		} else {
			$( '#offcanvas' ).show();
			$( 'body' ).addClass( 'offcanvas-active' );
			$( this ).attr( 'aria-expanded', 'true' );
		}
	});

	$( document ).on( 'click', function( e ) {
		if ( $( 'body' ).hasClass( 'offcanvas-active' ) &&
		e.target.id != 'offcanvas' && ! $( e.target ).parents( '#offcanvas' ).length &&
		e.target.id != 'offcanvas-toggle' && ! $( e.target ).parents( '#offcanvas-toggle' ).length ) {
			$( 'body' ).removeClass( 'offcanvas-active' );
			setTimeout( function() {
				$( '#offcanvas' ).hide();
			}, 500 );
		}
	});

	$( document ).ready( function() {
		$( '.testimonial-slider' ).slick( {
			slidesToShow: 1,
			arrows: false,
			autoplay: true,
			dots: true
		});
	});

}());
